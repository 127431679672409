























































import mixins from 'vue-typed-mixins'
import FlexibleCarousel1 from '@/builder/sections/section/shared/components/FlexibleCarousel1.vue'
import DefaultButton from '@/builder/sections/section/components/DefaultButton.vue'
import FlexibleGallery from '@/builder/sections/section/shared/mixins/FlexibleGallery'
export default mixins(FlexibleGallery).extend({
  name: 'FlexibleGallery1',
  components: { DefaultButton, FlexibleCarousel1 }
})
